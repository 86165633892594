import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BusinessList from "../components/BusinessList";
import { useStateValue } from "../StateProvider";
import "./Settings.css";

function Settings() {
  const [{ businesses }] = useStateValue();
  const [airlines, setAirlines] = useState([]);
  const [hotels, setHotels] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setAirlines(businesses.filter((business) => business.type === "airline"));
    setHotels(businesses.filter((business) => business.type === "hotel"));
  }, [businesses]);

  return (
    <div className="settings">
      <Button
        variant="contained"
        className="settings__addButton"
        onClick={() => history.push("/addBusiness")}
      >
        Add New Business
      </Button>
      {airlines.length > 0 && (
        <BusinessList title="Available Airlines" businesses={airlines} />
      )}
      {hotels.length > 0 && (
        <BusinessList title="Available Hotels" businesses={hotels} />
      )}
    </div>
  );
}

export default Settings;
