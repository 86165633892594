export const initialState = {
  user: null,
  members: [],
  businesses: [],
  userBusinesses: [],
};

const reducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };

    case "SET_MEMBERS":
      return {
        ...state,
        members: action.members,
      };

    case "SET_BUSINESSES":
      return {
        ...state,
        businesses: action.businesses,
      };

    case "SET_USER_BUSINESSES":
      return {
        ...state,
        userBusinesses: action.userBusinesses,
      };

    case "SIGN_OUT":
      return {
        ...state,
        user: null,
        members: [],
      };

    default:
      return state;
  }
};

export default reducer;
