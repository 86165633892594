import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { useStateValue } from "./StateProvider";
import { auth, createUserProfileDocument } from "./firebase";
import Header from "./components/Header";
import Login from "./components/Login";
import Sidebar from "./containers/Sidebar";
import Dashboard from "./containers/Dashboard";
import FormPage from "./containers/FormPage";
import AddEditMemberForm from "./components/AddEditMemberForm";
import ProfileForm from "./components/ProfileForm";
import AddEditBusinessForm from "./components/AddEditBusinessForm";
import Settings from "./containers/Settings";
import CategoryView from "./containers/CategoryView";
import MemberView from "./containers/MemberView";

function App() {
  const [{ user }, dispatch] = useStateValue();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // console.log("first useEffect");
    const createUserListener = (authUser) => {
      createUserProfileDocument(authUser).then((user) => {
        return user.onSnapshot((snapshot) => {
          dispatch({
            type: "SET_USER",
            user: {
              id: snapshot.id,
              ...snapshot.data(),
            },
          });
          setReady(true);
        });
      });
    };

    let unsubscribeUserListener = null;

    // console.log("Set up auth listener");
    let unsubscribeAuthStateChanged = auth.onAuthStateChanged((authUser) => {
      // console.log("Auth State Changed. THE USER IS >>> ", authUser);
      if (authUser) {
        // the user is logged in
        // console.log("Set up user listener");
        unsubscribeUserListener = createUserListener(authUser);
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        // the user is logged out
        // console.log("NO USER: Unsubscribe listners: user");
        if (unsubscribeUserListener) {
          unsubscribeUserListener();
          unsubscribeUserListener = null;
        }
        dispatch({
          type: "SIGN_OUT",
        });
        setReady(true);
      }
    });

    return () => {
      // console.log("Unsubscribe listners: auth");
      unsubscribeAuthStateChanged();
      unsubscribeAuthStateChanged = null;
    };
  }, [dispatch]);

  return (
    <Router>
      <div className="app">
        <Header />
        <div className="app__body">
          {!user && ready ? (
            <Login />
          ) : (
            ready && (
              <>
                <Sidebar />
                <Switch>
                  <Route path="/addMember/:businessId">
                    <FormPage>
                      <AddEditMemberForm />
                    </FormPage>
                  </Route>
                  <Route path="/editMember/:businessId/:memberId">
                    <FormPage>
                      <AddEditMemberForm edit />
                    </FormPage>
                  </Route>
                  <Route path="/addBusiness">
                    <FormPage>
                      <AddEditBusinessForm />
                    </FormPage>
                  </Route>
                  <Route path="/editBusiness/:businessId">
                    <FormPage>
                      <AddEditBusinessForm edit />
                    </FormPage>
                  </Route>
                  <Route path="/settings">
                    <Settings />
                  </Route>
                  <Route path="/profile">
                    <FormPage>
                      <ProfileForm />
                    </FormPage>
                  </Route>
                  <Route path="/member/:memberId">
                    <MemberView />
                  </Route>
                  <Route path="/airlines">
                    <CategoryView category="airline" />
                  </Route>
                  <Route path="/hotels">
                    <CategoryView category="hotel" />
                  </Route>
                  <Route path="/">
                    <Dashboard />
                  </Route>
                </Switch>
              </>
            )
          )}
        </div>
      </div>
    </Router>
  );
}

export default App;
