import React, { useEffect, useState } from "react";
import "./ProfileForm.css";
import { useStateValue } from "../StateProvider";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory } from "react-router-dom";
import {
  createUserMember,
  deleteUserMember,
  isMemberAssignedToAnyBusiness,
  saveUserProfileName,
} from "../firebase";
// import ConfirmDialog from "./ConfirmDialog";

function ProfileForm() {
  const [{ user, members }] = useStateValue();
  const [name, setName] = useState("");
  const [memberNameToAdd, setMemberNameToAdd] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  // const [memberToDelete, setMemberToDelete] = useState(null);
  // const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [membersAssigned, setMembersAssigned] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      setName(user.name);
    }
  }, [user]);

  useEffect(() => {
    if (user && members) {
      Promise.all(
        members.map(async (member) => {
          const assigned = await isMemberAssignedToAnyBusiness(
            user.id,
            member.id
          );
          return [member.id, assigned];
        })
      )
        .then((data) => {
          setMembersAssigned(Object.fromEntries(data));
        })
        .catch((error) => console.log("Error: ", error));
    }
  }, [user, members]);

  const handleSubmit = (event) => {
    event.preventDefault();
    saveUserProfileName(user.id, name);
    setDataChanged(false);
  };

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case "name":
        setName(target.value);
        setDataChanged(true);
        break;
      case "memberName":
        setMemberNameToAdd(target.value);
        break;
      default:
        console.log(target);
    }
  };

  const handleAddMember = () => {
    // Add member
    createUserMember(user, memberNameToAdd);
    setMemberNameToAdd(null);
  };

  const handleRemoveMember = (member) => {
    deleteUserMember(user, member.id);
    // Prompt confirmation
    // setMemberToDelete(member);
    // setOpenConfirmDlg(true);
  };

  // const handleDeleteConfirm = (confirmed) => {
  //   if (confirmed && memberToDelete.id) {
  //     // Delete member
  //     deleteUserMember(user, memberToDelete.id);
  //   }
  //   setOpenConfirmDlg(false);
  //   setMemberToDelete(null);
  // };

  const generateMembersList = () => {
    return members
      .sort((a, b) => a.createdAt - b.createdAt)
      .map((member) => (
        <ListItem key={member.id}>
          <ListItemText primary={member.name} />
          {!member.isMe && (
            <ListItemSecondaryAction>
              {membersAssigned && !membersAssigned[member.id] && (
                <IconButton
                  className="profileForm__membersRemoveButton"
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveMember(member)}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ));
  };

  return (
    <div className="profileForm">
      <h1>My Profile</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          className="profileForm__nameInput"
          name="name"
          label="Name"
          margin="normal"
          type="text"
          required
          InputLabelProps={{
            shrink: true,
          }}
          value={name}
          onChange={handleInputChange}
        />
        <div className="profileForm__controls">
          <Button
            variant="contained"
            className="profileForm__controlSave"
            disabled={!dataChanged}
            type="submit"
          >
            Save
          </Button>
          <Button
            variant="contained"
            className="profileForm__controlCancel"
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </div>

        <h4 className="profileForm__membersTitle">Members</h4>
        <div className="profileForm__members">
          <div className="profileForm__membersAdd">
            {members.length < 5 ? (
              <>
                <TextField
                  className="profileForm__memberNameInput"
                  name="memberName"
                  // label="Name"
                  placeholder="Member Name"
                  margin="normal"
                  type="text"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  value={memberNameToAdd}
                  onChange={handleInputChange}
                />
                <IconButton
                  className="profileForm__membersAddButton"
                  disabled={!memberNameToAdd}
                  aria-label="add"
                  onClick={handleAddMember}
                >
                  <AddIcon className="profileForm__membersAddButtonIcon" />
                </IconButton>
              </>
            ) : (
              <p>*Maximum of 5 members allowed</p>
            )}
          </div>

          <List className="profileForm__membersList">
            {generateMembersList()}
          </List>
        </div>
      </form>
      {/* <ConfirmDialog
        title={`Delete ${memberToDelete?.name}?`}
        confirmButtonText="DELETE"
        cancelButtonText="Cancel"
        open={openConfirmDlg}
        onClose={handleDeleteConfirm}
      >
        <p>
          Deleting member will also delete all associated data for the member.
        </p>
        <p>Contine with delete?</p>
      </ConfirmDialog> */}
    </div>
  );
}

export default ProfileForm;
