import React, { useEffect, useState } from "react";
import CategoryCard from "../components/CategoryCard";
import { createUserBusiness, deleteUserBusiness } from "../firebase";
import { useStateValue } from "../StateProvider";
import "./CategoryView.css";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import PeopleIcon from "@material-ui/icons/People";
import CloseIcon from "@material-ui/icons/Close";

function CategoryView({ category }) {
  const [{ user, businesses, userBusinesses }] = useStateValue();
  const [categoryBusinesses, setCategoryBusinesses] = useState([]);
  const [userCategoryBusinesses, setUserCategoryBusinesses] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    setCategoryBusinesses(
      businesses.filter((business) => business.type === category)
    );
  }, [businesses, category]);

  useEffect(() => {
    // get user businesses and rewards for type category
    setUserCategoryBusinesses(
      userBusinesses.filter((business) => business.type === category)
    );
  }, [userBusinesses, category]);

  const addBusiness = (businessId) => {
    createUserBusiness(user.id, businessId);
  };

  const removeBusiness = (businessId) => {
    deleteUserBusiness(user.id, businessId);
  };

  const generateBusinessList = () => {
    return categoryBusinesses.map((business) => {
      const userBusiness = userBusinesses.find((b) => b.id === business.id);
      return (
        <div className="categoryView__businessListItem" key={business.id}>
          {!userBusiness ? (
            <div
              className="categoryView__businessListItem__addIcon"
              onClick={() => {
                addBusiness(business.id);
              }}
            >
              <AddIcon />
            </div>
          ) : userBusiness.members &&
            Object.keys(userBusiness.members).length > 0 ? (
            <div className="categoryView__businessListItem__peopleIcon">
              <PeopleIcon />
            </div>
          ) : (
            <div
              className="categoryView__businessListItem__deleteIcon"
              onClick={() => {
                removeBusiness(business.id);
              }}
            >
              <DeleteIcon />
            </div>
          )}
          <div className="categoryView__businessListItem__name">
            {business.name}
          </div>
          <div className="categoryView__businessListItem__spacer"></div>
        </div>
      );
    });
  };

  return (
    <div className="categoryView">
      <CategoryCard
        category={category}
        userBusinesses={userCategoryBusinesses}
        onAdd={() => {
          setShowAdd(!showAdd);
        }}
        edit
      />
      {showAdd && (
        <div className="categoryView__businessList">
          <div className="categoryView__businessListHeader">
            {/* <h3>Add Businesses</h3> */}
            <div
              className="categoryView__businessListHeader__closeIcon"
              onClick={() => {
                setShowAdd(false);
              }}
            >
              <CloseIcon />
            </div>
          </div>
          {generateBusinessList()}
        </div>
      )}
    </div>
  );
}

export default CategoryView;
