import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBkZwhlAS69aLoL8Xlwbg_N2zWiwBmRwBc",
  authDomain: "rewardfolio.firebaseapp.com",
  databaseURL: "https://rewardfolio.firebaseio.com",
  projectId: "rewardfolio",
  storageBucket: "rewardfolio.appspot.com",
  messagingSenderId: "1045537805176",
  appId: "1:1045537805176:web:d4f0925960573baac4ee8d",
  measurementId: "G-26W9M0V0HJ",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const createUserProfileDocument = async (authUser) => {
  if (!authUser) return;

  const userRef = firestore.doc(`users/${authUser.uid}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const createdAt = new Date();
    const name = authUser.displayName || authUser.email;

    await userRef
      .set({
        name: name,
        email: authUser.email,
        createdAt,
      })
      .then(async () => {
        await userRef.collection("members").add({
          name: name,
          isMe: true,
          createdAt,
        });
      });
  }
  return userRef;
};

export const saveUserProfileName = async (userId, name) => {
  if (!userId || !name) return;

  const userRef = firestore.doc(`users/${userId}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) return;

  const modifiedAt = new Date();

  await userRef
    .update({
      name: name,
      modifiedAt,
    })
    .then(async () => {
      let query = userRef.collection("members").where("isMe", "==", true);

      query
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            // rename member isMe
            await userRef.collection("members").doc(doc.id).update({
              name: name,
              modifiedAt,
            });
          });
        })
        .catch((error) => {
          console.error("Error retrieving ME member: ", error);
        });
    });
};

//
// User Members functions
//

export const getUserMembersRef = (user) => {
  if (!user) return;

  return firestore.doc(`users/${user.id}`).collection("members");
};

export const createUserMember = async (user, memberName) => {
  if (!user || !memberName) return;

  const userRef = firestore.doc(`users/${user.id}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) return;

  const createdAt = new Date();

  await userRef.collection("members").add({
    name: memberName,
    isMe: false,
    createdAt,
  });
};

export const deleteUserMember = async (user, memberId) => {
  if (!user || !memberId) return;

  // FUTURE: If user businesses have member then don't delete
  if (!(await isMemberAssignedToAnyBusiness(user.id, memberId))) {
    await firestore
      .collection(`users/${user.id}/members`)
      .doc(memberId)
      .delete();
  }
};

export const isMemberAssignedToAnyBusiness = async (userId, memberId) => {
  if (!userId || !memberId) return false;

  return await firestore
    .collection(`users/${userId}/businesses`)
    .where(`members.${memberId}.memberId`, "==", memberId)
    .get()
    .then((qs) => !qs.empty)
    .catch((error) => console.log("Error: ", error));
};

//
// Business functions
//

export const getBusinessesRef = () => {
  return firestore.collection("businesses");
};

export const getBusiness = async (businessId) => {
  if (!businessId) return;

  return await firestore
    .doc(`businesses/${businessId}`)
    .get()
    .then((snapshot) => snapshot.data())
    .catch((error) => {
      console.log(`Error getting business ${businessId}: `, error);
      return;
    });
};

export const createBusiness = async (business) => {
  if (!business) return;

  let query = firestore
    .collection("businesses")
    .where("name", "==", business.name);

  let numFound = await query.get().then((querySnapshot) => querySnapshot.size);

  if (numFound) return;

  const createdAt = new Date();

  await firestore.collection("businesses").add({
    ...business,
    users: [],
    createdAt,
  });
};

export const updateBusiness = async ({ id, ...business }) => {
  if (!id && !business) return;

  const modifiedAt = new Date();

  await firestore
    .collection("businesses")
    .doc(id)
    .update({
      ...business,
      modifiedAt,
    });
};

export const deleteBusiness = async (businessId) => {
  if (!businessId) return;

  const businessRef = firestore.doc(`businesses/${businessId}`);
  const business = (await businessRef.get()).data();

  if (!business.users || business.users.length === 0) {
    await businessRef.delete();
  }
};

//
// User Business functions
//

export const getUserBusinessesRef = (userId) => {
  if (!userId) return;

  return firestore.collection(`users/${userId}/businesses`);
};

export const createUserBusiness = async (userId, businessId) => {
  if (!userId || !businessId) return;

  const userBusinessCollectionRef = getUserBusinessesRef(userId);

  return await userBusinessCollectionRef
    .doc(businessId)
    .get()
    .then(async (ss) => {
      if (!ss.exists) {
        // Add business to user
        const businessRef = firestore.doc(`businesses/${businessId}`);
        let businessData = await businessRef.get().then((ss) => ss.data());
        if (businessData.users && !businessData.users.includes(userId)) {
          businessData.users.push(userId);
        } else {
          businessData.users = [userId];
        }
        await businessRef.update({
          users: businessData.users,
        });

        delete businessData.users;
        await userBusinessCollectionRef.doc(businessId).set({
          addedAt: new Date(),
          ...businessData,
        });
      }
      return userBusinessCollectionRef.doc(businessId);
    })
    .catch((e) => {
      console.log("Error: ", e);
      return null;
    });
};

export const deleteUserBusiness = async (userId, businessId) => {
  if (!userId || !businessId) return;

  const userBusinessRef = getUserBusinessesRef(userId).doc(businessId);

  const snapShot = await userBusinessRef.get();

  if (snapShot.exists) {
    const data = snapShot.data();
    // Don't delete if members assigned
    if (data.members && Object.keys(data.members).length > 0) return;
    // Delete business from user
    await userBusinessRef.delete();
    // Delete user from business
    const businessRef = firestore.doc(`businesses/${businessId}`);
    let businessData = await businessRef.get().then((ss) => ss.data());
    if (businessData.users) {
      const users = businessData.users;
      users.splice(
        users.findIndex((user) => user === userId),
        1
      );
      await businessRef.update({
        users,
      });
    }
  }
};

//
// User Business Member functions
//

export const updateUserBusinessMember = async (
  userId,
  businessId,
  memberReward
) => {
  if (!userId || !businessId || !memberReward) return;

  const userBusinessRef = getUserBusinessesRef(userId).doc(businessId);

  const snapShot = await userBusinessRef.get();

  if (snapShot.exists) {
    const updatedData = { ...memberReward, modifiedAt: new Date() };
    const members = snapShot.data().members || {};

    await userBusinessRef.update({
      members: {
        ...members,
        [memberReward.memberId]: updatedData,
      },
    });
  }
};

export const deleteUserBusinessMember = async (
  userId,
  businessId,
  memberId
) => {
  if (!userId || !businessId || !memberId) return;

  const userBusinessRef = getUserBusinessesRef(userId).doc(businessId);

  const snapShot = await userBusinessRef.get();

  if (snapShot.exists) {
    const data = snapShot.data();
    const members = data.members || {};

    delete members[memberId];

    userBusinessRef.update({
      members: members,
    });
  }
};
