import { Badge } from "@material-ui/core";
import React from "react";
import "./SidebarOption.css";

function SidebarOption({ Icon, title, selected, alert }) {
  return (
    <div className={`sidebarOption ${selected && "sidebarOption--selected"}`}>
      {Icon && <Icon className="sidebarOption__icon" />}
      {alert ? (
        <Badge
          color="secondary"
          variant="dot"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <h3>{title}</h3>
        </Badge>
      ) : (
        <h3>{title}</h3>
      )}
    </div>
  );
}

export default SidebarOption;
