import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CategoryCard from "../components/CategoryCard";
import { useStateValue } from "../StateProvider";
import "./MemberView.css";

function MemberView() {
  const [{ userBusinesses }] = useStateValue();
  const [memberAirlineBusinesses, setMemberAirlineBusinesses] = useState([]);
  const [memberHotelBusinesses, setMemberHotelBusinesses] = useState([]);
  const { memberId } = useParams();

  useEffect(() => {
    const getCategoryMemberBusinesses = (category) => {
      return userBusinesses.filter(
        (business) =>
          business.type === category &&
          business.members &&
          business.members[memberId]
      );
    };

    setMemberAirlineBusinesses(getCategoryMemberBusinesses("airline"));
    setMemberHotelBusinesses(getCategoryMemberBusinesses("hotel"));
  }, [userBusinesses, memberId]);

  return (
    <div className="memberView">
      {memberAirlineBusinesses.length > 0 && (
        <CategoryCard
          category="airline"
          userBusinesses={memberAirlineBusinesses}
          memberId={memberId}
        />
      )}
      {memberHotelBusinesses.length > 0 && (
        <CategoryCard
          category="hotel"
          userBusinesses={memberHotelBusinesses}
          memberId={memberId}
        />
      )}
      {memberAirlineBusinesses.length === 0 &&
        memberHotelBusinesses.length === 0 && (
          <div className="memberView__empty">
            <img src="/images/crying.png" alt="" />
          </div>
        )}
    </div>
  );
}

export default MemberView;
