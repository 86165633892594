import React from "react";
import "./FormPage.css";

function FormPage({ title, children }) {
  return (
    <div className="formPage">
      <h1>{title}</h1>
      {children}
    </div>
  );
}

export default FormPage;
