import React from "react";
import "./CategoryCard.css";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import HotelIcon from "@material-ui/icons/Hotel";
import EditIcon from "@material-ui/icons/Edit";
import Company from "./Company";

function CategoryCard({
  category,
  userBusinesses,
  onAdd,
  edit,
  favoritesOnly,
  memberId,
}) {
  return (
    <div className="categoryCard">
      <div className="categoryCard__header">
        <div className="categoryCard__headerIcon">
          {category === "airline" ? (
            <LocalAirportIcon />
          ) : category === "hotel" ? (
            <HotelIcon />
          ) : null}
        </div>
        <div className="categoryCard__headerTitle">
          <h3>
            {category === "airline"
              ? "Airlines"
              : category === "hotel"
              ? "Hotels"
              : null}
          </h3>
        </div>
        {onAdd && (
          <div className="categoryCard__headerAddIcon" onClick={onAdd}>
            <EditIcon />
          </div>
        )}
      </div>
      {userBusinesses?.map((company) => (
        <Company
          key={company.id}
          id={company.id}
          name={company.name}
          url={company.url}
          membersRewards={company.members}
          edit={edit}
          favoritesOnly={favoritesOnly}
          memberId={memberId}
        />
      ))}
    </div>
  );
}

export default CategoryCard;
