import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import { auth } from "../firebase";
import { Button } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";

function Login() {
  const history = useHistory();
  const [signUp, setSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const loginWithEmailAndPasswordHandler = (event) => {
    console.log("Login with email >> ", email);
    event.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        alert(error.message);
      });
  };

  const registerWithEmailAndPasswordHandler = (event) => {
    console.log("Register with email >> ", email);
    event.preventDefault();
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        // it successfully created a new user with email and password
        if (auth) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        alert(error.message);
      });
  };

  // const loginWithGoogleHandler = (event) => {
  //   event.preventDefault();
  //   console.log("Login with google >> ", email);
  // };

  // const registerWithGoogleHandler = (event) => {
  //   event.preventDefault();
  //   console.log("Register with google >> ", email);
  // };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className="login">
      <div className={`login__container ${signUp && "login--signup"}`}>
        <div className="login__left">
          <h1>{!signUp ? "Login" : "Signup"}</h1>
          <form className="login__form">
            <div className="login__error">{error?.message}</div>
            <div
              className={`login__email ${
                error?.code === "auth/invalid-email" && "error"
              }`}
            >
              <MailOutlineIcon />
              <input
                type="email"
                name="userEmail"
                value={email}
                placeholder="username@gmail.com"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              className={`login__password ${
                error?.code === "auth/weak-password" && "error"
              }`}
            >
              <VpnKeyOutlinedIcon />
              <input
                type="password"
                name="userPassword"
                value={password}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <Button
              className="login__emailButton"
              type="submit"
              onClick={
                !signUp
                  ? loginWithEmailAndPasswordHandler
                  : registerWithEmailAndPasswordHandler
              }
            >
              {!signUp ? "Login" : "Sign up"}
            </Button>
          </form>
          {/* <p>- or -</p>
          <Button
            className="login__googleButton"
            onClick={
              !signUp ? loginWithGoogleHandler : registerWithGoogleHandler
            }
          >
            {!signUp ? "Login" : "Sign up"} with Google
          </Button> */}
        </div>
        <div className="login__right">
          <h1>{!signUp ? "Signup" : "Login"}</h1>
          <p>{!signUp ? `Don't h` : "H"}ave an account?</p>
          <p
            className="login__signUp"
            onClick={() => {
              setSignUp(!signUp);
              setError(null);
            }}
          >
            Click here to {!signUp ? "sign up" : "login"}.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
