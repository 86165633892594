import React from "react";
import "./BusinessList.css";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import { deleteBusiness } from "../firebase";
import { Link } from "react-router-dom";

function BusinessList({ title, businesses }) {
  const handleRemoveBusiness = (businessId) => {
    // Delete business
    deleteBusiness(businessId);
  };

  const generateList = () =>
    businesses.map((business) => (
      <ListItem key={business.id}>
        <ListItemText primary={business.name} secondary={business.url} />
        <ListItemSecondaryAction>
          {(!business.users || business.users.length === 0) && (
            <IconButton
              className="businessList__businessRemoveButton"
              edge="end"
              aria-label="delete"
              onClick={() => handleRemoveBusiness(business.id)}
            >
              <ClearIcon />
            </IconButton>
          )}
          <Link to={`/editBusiness/${business.id}`}>
            <IconButton
              className="businessList__businessEditButton"
              edge="end"
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
          </Link>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  return (
    <div className="businessList">
      <h3>{title}</h3>
      <List className="businessList__list" dense>
        {generateList()}
      </List>
    </div>
  );
}

export default BusinessList;
