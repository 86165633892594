import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createBusiness, getBusiness, updateBusiness } from "../firebase";
import "./AddEditBusinessForm.css";

function AddEditBusinessForm({ edit }) {
  const { businessId } = useParams();
  const [name, setName] = useState("");
  const [type, setType] = useState("airline");
  const [url, setUrl] = useState("");
  const [rewardChartUrl, setRewardChartUrl] = useState("");
  const history = useHistory();

  const types = [
    { id: "airline", name: "Airline" },
    { id: "hotel", name: "Hotel" },
  ];

  useEffect(() => {
    // get business data and fill form if editing
    const fetchData = async () => {
      const data = await getBusiness(businessId);
      if (data) {
        setName(data.name);
        setType(data.type);
        setUrl(data.url);
        setRewardChartUrl(data.rewardChartUrl);
      }
    };

    if (edit) {
      fetchData();
    }
  }, [edit, businessId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (edit) {
      updateBusiness({ id: businessId, name, type, url, rewardChartUrl });
    } else {
      createBusiness({ name, type, url, rewardChartUrl });
    }
    history.goBack();
  };

  const handleCancel = (event) => {
    history.goBack();
  };

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case "name":
        setName(target.value);
        break;
      case "type":
        setType(target.value);
        break;
      case "url":
        setUrl(target.value);
        break;
      case "rewardUrl":
        setRewardChartUrl(target.value);
        break;
      default:
        console.log(target);
    }
  };

  const generateTypeList = () => {
    return types.map(({ name, id }) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ));
  };

  return (
    <div className="addEditBusinessForm">
      <h1>{!edit ? `Add Business` : `Edit Business`}</h1>
      <form onSubmit={handleSubmit}>
        <div className="addEditBusinessForm__businessName">
          <TextField
            className="addEditBusinessForm__businessNameEntry"
            name="name"
            label="Name"
            margin="normal"
            type="text"
            required
            InputLabelProps={{
              shrink: true,
            }}
            value={name}
            onChange={handleInputChange}
          />
        </div>
        <div className="addEditBusinessForm__businessType">
          <FormControl className="addEditBusinessForm__businessTypeSelect">
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              name="type"
              labelId="type-select-label"
              value={type}
              onChange={handleInputChange}
            >
              {generateTypeList()}
            </Select>
          </FormControl>
        </div>
        <div className="addEditBusinessForm__businessUrl">
          <TextField
            className="addEditBusinessForm__businessUrlEntry"
            name="url"
            label="Website Url"
            placeholder="https://www.business.com"
            margin="normal"
            type="url"
            InputLabelProps={{
              shrink: true,
            }}
            value={url}
            onChange={handleInputChange}
          />
        </div>
        <div className="addEditBusinessForm__rewardChartUrl">
          <TextField
            className="addEditBusinessForm__rewardChartUrlEntry"
            name="rewardUrl"
            label="Reward Chart Url"
            placeholder="https://www.business.com/reward"
            margin="normal"
            type="url"
            InputLabelProps={{
              shrink: true,
            }}
            value={rewardChartUrl}
            onChange={handleInputChange}
          />
        </div>
        <div className="addEditBusinessForm__controls">
          <Button
            variant="contained"
            className="addEditBusinessForm__controlSave"
            disabled={!name}
            type="submit"
          >
            Save
          </Button>
          <Button
            variant="contained"
            className="addEditBusinessForm__controlCancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddEditBusinessForm;
