import React, { useEffect, useState } from "react";
import CategoryCard from "../components/CategoryCard";
import { useStateValue } from "../StateProvider";
import "./Dashboard.css";

function Dashboard() {
  const [{ userBusinesses }] = useStateValue();
  const [userAirlineBusinesses, setUserAirlineBusinesses] = useState([]);
  const [userHotelBusinesses, setUserHotelBusinesses] = useState([]);
  const [DOMReady, setDOMReady] = useState(false);

  useEffect(() => {
    setDOMReady(true);
  }, []);

  useEffect(() => {
    const getCategoryUserBusinesses = (category) =>
      userBusinesses.filter(
        (business) =>
          business.type === category &&
          business.members &&
          Object.entries(business.members).some(
            ([key, value]) => value.favorite
          )
      );

    setUserAirlineBusinesses(getCategoryUserBusinesses("airline"));
    setUserHotelBusinesses(getCategoryUserBusinesses("hotel"));
  }, [userBusinesses]);

  return (
    <div className="dashboard">
      {userAirlineBusinesses.length > 0 && (
        <CategoryCard
          category="airline"
          userBusinesses={userAirlineBusinesses}
          favoritesOnly
        />
      )}
      {userHotelBusinesses.length > 0 && (
        <CategoryCard
          category="hotel"
          userBusinesses={userHotelBusinesses}
          favoritesOnly
        />
      )}
      {DOMReady &&
        userAirlineBusinesses.length === 0 &&
        userHotelBusinesses.length === 0 && (
          <div className="dashboard__emptyInfo">
            <h1>
              Please configure a member to show on the dashboard to be displayed
              here
            </h1>
          </div>
        )}
    </div>
  );
}

export default Dashboard;
