import React, { useEffect, useState } from "react";
import "./AddEditMemberForm.css";
import {
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import {
  deleteUserBusinessMember,
  updateUserBusinessMember,
} from "../firebase";

function AddEditMemberForm({ edit }) {
  const history = useHistory();
  const params = useParams();
  const [{ user, members, userBusinesses }] = useStateValue();
  const [memberId, setMemberId] = useState(params.memberId || "");
  const [points, setPoints] = useState("");
  const [pointsExp, setPointsExp] = useState(null);
  const [certificateExp, setCertificateExp] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [showOnDashboard, setShowOnDashboard] = useState(false);
  const [businessName, setBusinessName] = useState(null);
  const [businessMembers, setBusinessMembers] = useState([]);
  const [memberName, setMemberName] = useState(null);

  useEffect(() => {
    const selectedMember = members.find((m) => m.id === params.memberId);
    setMemberName(selectedMember?.name);
    const business = userBusinesses.find((b) => b.id === params.businessId);

    if (business) {
      const memberReward = business?.members
        ? business?.members[params.memberId]
        : {};

      setBusinessName(business.name);
      if (business.members) {
        setBusinessMembers(Object.keys(business.members));
      }

      if (memberReward) {
        setPoints(memberReward.points);
        setPointsExp(memberReward.pointsExpiration?.toDate() || null);
        setCertificates(
          memberReward.certificates?.map((cert) => cert.toDate()) || []
        );
        setShowOnDashboard(memberReward.favorite || false);
      }
    }
  }, [params, members, userBusinesses]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      memberId: memberId,
      points: +points,
      pointsExpiration: pointsExp,
      certificates: certificates,
      favorite: showOnDashboard,
    };
    await updateUserBusinessMember(user.id, params.businessId, data);
    history.goBack();
  };

  const handleDelete = () => {
    deleteUserBusinessMember(user.id, params.businessId, memberId);
    history.goBack();
  };

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case "member":
        setMemberId(target.value);
        break;
      case "points":
        setPoints(target.value);
        break;
      default:
        console.log(target);
    }
  };

  const handleDashboardSelect = ({ target }) => {
    setShowOnDashboard(target.checked);
  };

  const handlePointsExpChange = (date) => {
    setPointsExp(date);
  };

  const handleCertificateExpChange = (date) => {
    setCertificateExp(date);
  };

  const handleAddCertificate = () => {
    const newCerts = [...certificates];
    newCerts.push(certificateExp);
    setCertificates(newCerts);
    setCertificateExp(null);
  };

  const handleRemoveCertificate = (index) => {
    const newCerts = [...certificates];
    newCerts.splice(index, 1);
    setCertificates(newCerts);
  };

  const generateCertificateList = () =>
    certificates.map((cert, index) => (
      <ListItem key={index}>
        <ListItemText
          primary={`Certificate #${index + 1}`}
          secondary={`expiring ${format(cert, "MMMM d, yyyy")}`}
        />
        <ListItemSecondaryAction>
          <IconButton
            className="addEditMemberForm__certificatesRemoveButton"
            edge="end"
            aria-label="delete"
            onClick={() => handleRemoveCertificate(index)}
          >
            <ClearIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  const generateMemberList = () => {
    return members.map(({ name, id }) => (
      <MenuItem key={id} value={id} disabled={businessMembers.includes(id)}>
        {name}
      </MenuItem>
    ));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="addEditMemberForm">
        <h1>
          {!edit
            ? `Add Member to ${businessName}`
            : `Edit ${memberName} for ${businessName}`}
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="addEditMemberForm__member">
            <FormControl className="addEditMemberForm__memberControl">
              <InputLabel id="member-select-label">Member</InputLabel>
              <Select
                name="member"
                labelId="member-select-label"
                value={memberId}
                disabled={edit}
                disableUnderline={edit}
                onChange={handleInputChange}
              >
                {generateMemberList()}
              </Select>
            </FormControl>
            <FormGroup className="addEditMemberForm__dashboardSelect">
              <FormControlLabel
                value="dashboard"
                control={
                  <Switch
                    color="primary"
                    checked={showOnDashboard}
                    onChange={handleDashboardSelect}
                  />
                }
                label="Dashboard"
                labelPlacement="top"
              />
            </FormGroup>
          </div>
          <div className="addEditMemberForm__points">
            <TextField
              className="addEditMemberForm__pointsEntry"
              name="points"
              label="Points"
              margin="normal"
              type="number"
              InputLabelProps={{
                shrink: edit,
              }}
              value={points}
              onChange={handleInputChange}
            />
            <KeyboardDatePicker
              className="addEditMemberForm__pointsExp"
              autoOk
              disableToolbar
              disablePast
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Points expiration"
              value={pointsExp}
              onChange={handlePointsExpChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
          <div className="addEditMemberForm__controls">
            <Button
              variant="contained"
              className="addEditMemberForm__controlSave"
              disabled={!memberId}
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="addEditMemberForm__controlCancel"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            {edit && (
              <Button
                variant="contained"
                className="addEditMemberForm__controlDelete"
                onClick={handleDelete}
              >
                <DeleteIcon /> Delete
              </Button>
            )}
          </div>
          <h4 className="addEditMemberForm__certificatesTitle">Certificates</h4>
          <div className="addEditMemberForm__certificates">
            <div className="addEditMemberForm__certificatesAdd">
              <KeyboardDatePicker
                className="addEditMemberForm__certificateExp"
                autoOk
                disableToolbar
                disablePast
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                label="Certificate expiration"
                value={certificateExp}
                onChange={handleCertificateExpChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <IconButton
                className="addEditMemberForm__certificatesAddButton"
                disabled={!certificateExp}
                aria-label="add"
                onClick={handleAddCertificate}
              >
                <AddIcon className="addEditMemberForm__certificatesAddButtonIcon" />
              </IconButton>
            </div>
            <List className="addEditMemberForm__certificatesList">
              {generateCertificateList()}
            </List>
          </div>
        </form>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default AddEditMemberForm;
