import React from "react";
import "./Header.css";
import { useStateValue } from "../StateProvider";
import { auth } from "../firebase";

function Header() {
  const [{ user }] = useStateValue();

  const handleAuthentication = () => {
    if (user) {
      auth.signOut();
    }
  };

  return (
    <div className="header">
      <h1>Rewardsfolio</h1>
      <div className="header__nav">
        <div onClick={handleAuthentication} className="header__option">
          <span className="header__optionLineOne">
            {/* Welcome, {user ? user.name : "Guest"} */}
            {user && `Welcome, ${user.name}`}
          </span>
          <span className="header__optionLineTwo header--clickable">
            {user ? "Sign Out" : ""}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
