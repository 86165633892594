import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import SidebarOption from "../components/SidebarOption";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import HotelIcon from "@material-ui/icons/Hotel";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link, useLocation } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import { add, isBefore } from "date-fns";
import {
  getBusinessesRef,
  getUserBusinessesRef,
  getUserMembersRef,
} from "../firebase";

function Sidebar() {
  const [{ user, members, userBusinesses }, dispatch] = useStateValue();
  const [memberAlerts, setMemberAlerts] = useState({});
  const location = useLocation();

  // Setup listener for businesses
  useEffect(() => {
    return getBusinessesRef()
      .orderBy("name")
      .onSnapshot((snapshot) => {
        const businesses = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({
          type: "SET_BUSINESSES",
          businesses: businesses,
        });
      });
  }, [dispatch]);

  // Setup listener for user's businesses
  useEffect(() => {
    if (user?.id) {
      return getUserBusinessesRef(user.id)
        .orderBy("name")
        .onSnapshot((snapshot) => {
          const userBusinesses = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          dispatch({
            type: "SET_USER_BUSINESSES",
            userBusinesses: userBusinesses,
          });
        });
    }
  }, [user, dispatch]);

  // Setup listener for user's members
  useEffect(() => {
    if (user?.id) {
      const members = getUserMembersRef({ id: user.id });
      return members.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((qds) => ({
          id: qds.id,
          ...qds.data(),
        }));
        dispatch({
          type: "SET_MEMBERS",
          members: data,
        });
      });
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (members.length > 0 && userBusinesses.length > 0) {
      const memberAlertsInit = Object.fromEntries(
        members.map((m) => [m.id, 0])
      );
      const memberAlerts = userBusinesses.reduce((results, business) => {
        if (business.members && Object.keys(business.members).length > 0) {
          Object.keys(business.members).forEach((key) => {
            const member = business.members[key];
            member.certificates.forEach((timestamp) => {
              if (
                isBefore(timestamp.toDate(), add(new Date(), { months: 3 }))
              ) {
                results[member.memberId]++;
              }
            });
            if (
              member.pointsExpiration &&
              isBefore(
                member.pointsExpiration.toDate(),
                add(new Date(), { months: 3 })
              )
            ) {
              results[member.memberId]++;
            }
          });
        }
        return results;
      }, memberAlertsInit);
      setMemberAlerts(memberAlerts);
    }
  }, [members, userBusinesses]);

  return (
    <div className="sidebar">
      <Link to="/">
        <SidebarOption
          Icon={DashboardIcon}
          title="Dashboard"
          selected={location.pathname === "/"}
        />
      </Link>
      <Link to="/airlines">
        <SidebarOption
          Icon={LocalAirportIcon}
          title="Airlines"
          selected={location.pathname === "/airlines"}
        />
      </Link>
      <Link to="/hotels">
        <SidebarOption
          Icon={HotelIcon}
          title="Hotels"
          selected={location.pathname === "/hotels"}
        />
      </Link>
      <hr />
      <div className="sidebar__members">
        <PeopleIcon className="sidebar__membersIcon" />
      </div>
      {/* Members list */}
      {members
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((member) => (
          <Link to={`/member/${member.id}`} key={member.id}>
            <SidebarOption
              title={member.name}
              selected={location.pathname === `/member/${member.id}`}
              alert={memberAlerts[member.id] > 0}
            />
          </Link>
        ))}
      <hr />
      <Link to="/profile">
        <SidebarOption
          Icon={PersonIcon}
          title="Profile"
          selected={location.pathname === "/profile"}
        />
      </Link>
      {user?.isAdmin && (
        <Link to="/settings">
          <SidebarOption
            Icon={SettingsIcon}
            title="Settings"
            selected={location.pathname === "/settings"}
          />
        </Link>
      )}
    </div>
  );
}

export default Sidebar;
