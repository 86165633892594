import React, { useEffect, useState } from "react";
import "./Company.css";
import { Link } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import format from "date-fns/format";
import add from "date-fns/add";
import isBefore from "date-fns/isBefore";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LanguageIcon from "@material-ui/icons/Language";
import EditIcon from "@material-ui/icons/Edit";

function Company({
  id,
  name,
  url,
  membersRewards,
  edit,
  favoritesOnly,
  memberId,
}) {
  const [{ members }] = useStateValue();
  const [rewards, setRewards] = useState([]);

  const formatExpirationTimestamp = (timestamp) =>
    `expires ${format(timestamp.toDate(), "MM/dd/yyyy")}`;

  useEffect(() => {
    if (membersRewards) {
      const rewards = Object.keys(membersRewards)
        .map((memberKey) => ({
          memberName: members.find((m) => m.id === memberKey)?.name,
          ...membersRewards[memberKey],
        }))
        .filter((reward) => (favoritesOnly ? reward.favorite : true))
        .filter((reward) => (memberId ? memberId === reward.memberId : true))
        .sort((a, b) =>
          a.memberName < b.memberName ? -1 : a.memberName > b.memberName ? 1 : 0
        );
      setRewards(rewards);
    } else {
      setRewards([]);
    }
  }, [membersRewards, members, favoritesOnly, memberId]);

  return (
    <div className="company">
      <div className="company__header">
        <div className="company__name">
          {name}
          {url && (
            <div
              className="company__webSite"
              onClick={() => window.open(url, "_blank")}
            >
              <LanguageIcon fontSize="small" />
            </div>
          )}
        </div>
        {edit && (
          <div className="company__addIcon">
            <Link to={`/addMember/${id}`}>
              <PersonAddIcon />
            </Link>
          </div>
        )}
      </div>
      <div className="company__rewards">
        {rewards.map((reward) => (
          <div className="company__reward" key={reward.memberId}>
            <div className="company__rewardMember">
              <div className="company__rewardMember__lineOne">
                <div className="company__rewardMemberName">
                  {reward.memberName}
                </div>
                {edit && (
                  <>
                    <div className="company__rewardMember__editIcon">
                      <Link to={`/editMember/${id}/${reward.memberId}`}>
                        <EditIcon fontSize="small" />
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <div className="company__rewardMember__lineTwo">
                {reward.certificates.map((cert, index) => (
                  <div
                    className={`company__rewardMemberCertificate ${
                      isBefore(cert.toDate(), add(new Date(), { months: 3 }))
                        ? "company__rewardMemberCertificate--alert"
                        : ""
                    }`}
                    key={cert}
                  >
                    {`Certificate #${index + 1}: ${formatExpirationTimestamp(
                      cert
                    )}`}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`company__rewardPoints ${
                reward.pointsExpiration &&
                isBefore(
                  reward.pointsExpiration.toDate(),
                  add(new Date(), { months: 3 })
                )
                  ? "company__rewardPoints--alert"
                  : ""
              }`}
            >
              {reward.points}
              {reward.pointsExpiration && (
                <p>{formatExpirationTimestamp(reward.pointsExpiration)}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Company;
